import TitleBarImage from '../../../../assets/titlebar.png'
import { Box, Grid, Typography } from '@mui/material'
import TrustpilotRating from '../Elements/TrustpilotRating'
import BlueButton from '../../../ui-components/GreenButton'
import WhiteButton from '../../../ui-components/WhiteButton'
import { CLIENT_ID } from 'services/googleOneTapLogin/config'
const LOGIN_END_POINT = process.env.REACT_APP_LANDING_URL
const HeaderImage = process.env.PUBLIC_URL + '/images/Dashboard.webp'

// const TitleBarImage = process.env.PUBLIC_URL + '/image/'

export default function Header() {
  return (
    <Box
      component={'section'}
      sx={{
        padding: {
          xs: '50px 15px 15px 15px',
          md: '160px 0px 80px 112px'
        },
        width: '100%',
        '@media (max-width: 960px)': {
          flexDirection: 'column'
        },
        background: '#f8f9fb'
      }}
      id="home"
    // className="container flexSpaceCenter"
    >
      <link rel="preload" href={TitleBarImage} as="image" />
      <link rel="preload" href={HeaderImage} as="image" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div id='google-one-tap-container' style={{ position: 'fixed', top: '75px', zIndex: 999 }}>
        </div>
      </Box>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5.5}
          sx={{
            // width: '40%',
            height: '100%',
            order: '0',
            '@media (max-width: 960px)': {
              width: '100%',
              order: '1',
              margin: '50px 0',
              textAlign: 'center'
            },
            '@media (max-width: 560px)': {
              margin: '0'
            }
          }}
          className="flexCenter"
        >
          <Box sx={{ mt: '80px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start',
                  lg: 'flex-start'
                }
              }}
            >
              <Typography
                sx={{
                  color: 'var(--text-icons-light-primary, #2D146F)',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '22px',
                  borderRadius: 'var(--roundness-full-inside, 128px)',
                  background: 'var(--background-light-primary-second, #F1F0F6)',
                  padding: '2px 10px',
                  marginBottom: {
                    xs: '5px',
                    md: ''
                  }
                }}
              >
                🌶️ Spice up your earnings
              </Typography>
            </Box>
            <Typography
              variant="h1"
              sx={{
                color: '#323539',
                fontSize: { xs: '42px', md: '64px' },
                display: 'flex',
                marginRight: { xs: '0', md: '27px' },
                position: 'relative',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: {
                  // xs: '10px',
                  md: '68px'
                },
                letterSpacing: '-1.28px'
              }}
            >
              Diversify Your Website Revenue
            </Typography>
            <Box
              className="header-sub-text"
              sx={{
                // maxWidth: '470px',
                color: 'var(--text-icons-light-base-second, #858C95)',
                fontSize: { xs: '16px', md: '24px' },
                padding: '15px 0 32px 0',
                '@media (max-width: 960px)': {
                  textAlign: 'center',
                  maxWidth: '100%'
                },
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '26px',
                letterSpacing: {
                  xs: '1px',
                  md: '-0.1px'
                }
              }}
            >
              Multiple Income Streams, One Solution
            </Box>
            <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} gap={'16px'}>
              <BlueButton title="Sign Up (Free)" />
              <WhiteButton title="Book Demo" />
            </Box>
            <Box
              className="header-rating"
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: { md: 'flex-start', xs: 'center' }
              }}
            >
              <TrustpilotRating />
            </Box>
          </Box>
        </Grid>
        <Grid
          className="header-image"
          item
          xs={12}
          md={6.5}
          sx={{
            // width: '55%',
            height: '100%',
            '@media (max-width: 960px)': {
              width: '100%',
              order: '1',
              marginTop: '30px'
            }
          }}
        >
          <Box
            sx={
              {
                // display: 'flex',
                // justifyContent: 'flex-end'
              }
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { xs: 'center', md: 'flex-end', lg: 'flex-end' },
                alignItems: 'center',
                position: 'relative',
                zIndex: 9,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                '.img': {
                  '@media (max-width: 560px)': {
                    width: '100%',
                    height: 'auto'
                  }
                },
                borderRadius: '10px'
                //   border: '1px solid blue'
              }}
            >
              <img
                src={TitleBarImage}
                loading="eager"
                width="100%"
                height="80%"
                aria-label={'For office Title'}
                alt=" officeTitle"
                style={{ zIndex: 9, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
              />
              <img
                src={HeaderImage}
                loading="eager"
                width="100%"
                height="80%"
                aria-label={'For office'}
                alt="officeHeader"
                style={{ zIndex: 9, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
